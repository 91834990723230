@use 'masonry_modules_commons' as *;
@use './destination_masonry_link/destination_masonry_link';

@include desktopStyles {
  .masonry-modules {
    .masonry-module-items {
      padding-top: 20px;
    }

    .block {
      cursor: pointer;

      &:hover {
        z-index: 10;
        transform: scale(1.0825);

        .image {
          background: #000;

          img {
            opacity: 0.8;
          }
        }

        .view-more {
          opacity: 1;
          height: auto;
          max-height: 100%;
          padding-top: 25px;
        }
      }

      .view-more {
        opacity: 0;
        height: 0;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s;
      }

      .title {
        padding-bottom: 25px;
        line-height: 1em;
      }

      .t-label {
        margin-bottom: 24px;
      }
    }

  }
}

@include tabletStyles {
  .masonry-modules-items {
    .masonry-modules-item {
      .view-more {
        opacity: 1;
        display: block;
        height: auto;
        max-height: 100%;
        padding-top: 15px;
        color: var(--c_white);
        text-align: center;
      }

      .title {
        @include fontSerifBold;
        @include fontSize(26);
        padding-bottom: 15px;
        line-height: 1.2em;
      }

      .t-label {
        margin-bottom: 14px;
      }
    }
  }
}

.ada {
  .masonry-modules-items .masonry-modules-item img {
    filter: brightness(0.55);
  }

  .wrapper.masonry-modules-item-title-header {
    margin-bottom: 24px;
  }
}
