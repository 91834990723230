@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.news-module {
  > .wrapper {
    overflow: hidden;

    &.block-title-header {
      overflow: visible;
    }
  }

  .card-container {
    .title {
      color: var(--c_grey_600);
    }
  }

  .block-link {
    &.with-icon {
      a {
        @include icon(arrow-right-fat) {
          position: absolute;
          top: 50%;
          left: 100%;
          transform: translateY(-50%);
          margin-top: 1px;
          margin-left: 7px;
          font-size: 15px;
        }
      }
    }
  }
}
