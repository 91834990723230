@use 'internal_nav_commons' as *;

@include commonTabletDesktop {
  .internal-nav-cnt-nfh {
    .internal-nav {
      .internal-nav-list {
        padding: 18px;

        .menu_item {
          padding: 0 10px;
          margin: auto 5px;

          .fake-link {
            padding: 5px 15px;
            color: var(--interactive-text-base);
          }

          &:hover,
          &.active {
            .fake-link {
              @include interactiveElement;
            }
          }
        }
      }
    }
  }

  .internal-nav {
    ul {
      .multi-button {
        display: none;
        padding: 0;

        &::after {
          display: none;
        }
      }

      .menu_item {
        a {
          display: flex;

          .s {
            display: flex;

            &::after {
              display: none;
            }
          }
        }
      }
    }

    &:not(.centered) {
      ul {
        &.auto-width {
          li {
            flex: 1 0 auto;
          }
        }
      }
    }

    &.centered {
      ul,
      .menu {
        justify-content: center;

        .multi-button {
          padding: 0;
        }
      }
    }

    &.start {
      ul,
      .menu {
        justify-content: start;

        .multi-button {
          padding: 0;
        }
      }
    }

    .hotel-page & {
      ul {
        li {
          padding: 0 24px;

          &::after {
            height: 14px;
          }

          a {
            padding: 6px 0;
          }
        }
      }
    }
  }
}

@include tabletStyles {
  .internal-nav-cnt-nfh {
    .internal-nav {
      overflow-x: auto;

      &.centered {
        display: flex;
        justify-content: center;
        overflow-x: auto;

        ul {
          flex: 0;
          justify-content: flex-start;
        }
      }
    }
  }
}
