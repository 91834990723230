@use 'destination_masonry_link_commons' as *;

.destination-masonry-link {
  &__image {
  }

  &__info {

    &--title {
      @include fontSize(32);
    }
  }
}
