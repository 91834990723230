@use 'home_tabs_commons' as *;
@use '../home_tabs_item/home_tabs_item';

@include commonTabletDesktop {
  .home-tabs {
    .image {
      position: relative;
    }

    .block-title {
      margin-bottom: 20px;
    }

    .block-subtitle {
      margin-bottom: 8px;
    }

    .block-tabs {
      margin-bottom: 24px;

      &.no-swiper {
        display: flex;
        flex: 0 0 100%;
        justify-content: center;
      }

      .block-tabs-name {
        width: auto;

        .block-tabs-name-link {
          padding: 10px 15px;
          margin: 0 12px;

          .s {
            margin-left: 0;
          }

          &.active, &:hover {
            @include interactiveElement;
          }
        }
      }
    }

    .gallery-star-prestige {
      .ib-gallery {
        padding: 15px 40px 0;

        .is-fullscreen {
          .navigation {
            left: 50%;
          }
        }
      }
    }
  }
}

@include desktopStyles {
  .home-tabs {
    .block-description {
      max-width: 70%;
    }
  }
}

@include tabletStyles {
  .home-tabs {
    .block-description {
      max-width: 100%;
    }

    &.is-lightbox {
      .wrapper-inner {
        padding: 0 20px;
      }

      .tabs {
        .e-filter-tabs {
          .content-tab {
            width: 33.33%;
            white-space: unset;
          }
        }
      }
    }
  }
}

.home-tabs {
  .gallery-star-prestige {
    width: 80%;
    padding-top: 40px;
    margin: auto;
  }
}
