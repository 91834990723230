@use 'call_center_commons' as *;

.block-call-center {
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .call-center{
    $self: &;
    display: flex;
    width: 80%;
    padding: 50px 0;

    &__chat{
      gap: 20px;
      justify-content: flex-start;
      width: 50%;

      &-image{
        width: 128px;
        height: 128px;

        &-circle{
          border-radius: 100%;
        }
      }

      &-bubbles{
        &-bubble{
          padding: 20px;
        }
      }
    }

    &__title{
      width: 50%;

      &-text{
        @include fontSize(24);
        line-height: 32px;
        text-align: center;
      }

      // &-button{
      //   background-color: var(--c_white);
      // }
    }

    &__subtitle{
      @include fontSize(24);
      line-height: 32px;
    }
  }
}
