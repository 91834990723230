@use '../../base_css/abstracts/abstracts' as *;

.social-list {
  display: flex;

  li {
    flex: 0 0 auto;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    span {
      display: block;
      width: 64px;
      height: 64px;
      border: 1px solid var(--c_cloud);
      font-size: 23px;
      line-height: 60px;
      text-align: center;

      &.s-twitter {
        color: #00ACED;
      }

      &.s-facebook {
        color: #3B5998;
      }

      &.s-youtube {
        color: #E52D27;
      }

      &.s-instagram {
        color: #262626;
        font-size: 20px;
      }
    }
  }
}
