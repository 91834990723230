@use '../../../base_css/abstracts/abstracts' as *;
@forward '../../../base_css/abstracts/abstracts';

.multi-slider-nav {
  padding-top: 0;
  margin-left: 10px;

  .nav-prev,
  .nav-next {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border: solid 1px var(--c_primary);
    border-radius: 100%;
    box-shadow: 0 2px 4px 0 rgba(200, 200, 200, 67%);

    &.swiper-button-disabled {
      border: solid 1px #8B9199;
      box-shadow: none;
    }
  }

  .nav-prev {
    @include icon(arrow-left, before) {
      color: var(--c_primary);
      font-size: 7px;
    }
    float: left;

    &.swiper-button-disabled {
      &::before {
        color: #8B9199;
      }
    }
  }

  .nav-next {
    @include icon(arrow-right) {
      color: var(--c_primary);
      font-size: 7px;
    }
    float: right;

    &.swiper-button-disabled {
      &::after {
        color: #8B9199;
      }
    }
  }

  .nav-next {
    margin-left: 20px;
  }
}
