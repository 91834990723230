@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.masonry-modules {
  .block {
    position: relative;
    transition: all 0.3s;

    .image {
      @include image_filter;
      position: relative;
      display: block;
      width: 100%;
      padding-top: 150.16%;
      transition: all 0.3s;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 0.3s;
    }

    > a {
      position: static;

      &::before {
        display: none;
      }
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &::before {
        display: none;
      }
    }

    .view-more {
      display: block;
      color: var(--c_white);
      text-align: center;
    }

    .desc {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      overflow: auto;
    }

    .t-label {
      color: var(--color-body-font);
    }

    .t-label,
    .title {
      color: var(--c_white);
      text-align: center;
    }

    .title {
      position: relative;

      &::after {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        content: '';
        display: block;
        width: 30px;
        height: 2px;
        border-radius: 1px;
        background: var(--c_white);
      }
    }
  }
}
