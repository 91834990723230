@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.tabs-menu {
  display: flex;

  > li {
    position: relative;
    flex: 0 0 auto;
    align-self: center;
    padding: 0 24px;

    &:first-child {
      padding-left: 0;

      &::before {
        display: none;
      }
    }

    &:last-child {
      padding-right: 0;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      content: '';
      width: 1px;
      height: 100%;
      background: #D2DFED;
    }

    a {
      @include fontSansBold;
      @include fontSize(14);
      color: var(--c_primary);
      line-height: 14px;
      transition: color 0.3s ease;

      &:hover {
        color: var(--c_blue_700);
      }
    }

    &.active a,
    a.active {
      position: relative;
      color: var(--c_blue_700);

      &::after {
        position: absolute;
        left: -5px;
        bottom: -8px;
        content: '';
        width: calc(100% + 10px);
        height: 2px;
        background: var(--c_blue_700);
      }
    }
  }
}
