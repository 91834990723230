@use 'footer_commons' as *;

@include commonTabletDesktop {
  .footer-nav {
    .footer-nav-container {
      padding-top: 40px;

      .footer-nav-content {
        .wrapper {
          position: relative;
          margin-top: 30px;
          margin-bottom: 30px;

          .opened-container {
            padding-bottom: 15px;

            .dropdown.title {
              @include icon(arrow-up) {
                margin-top: 8px;
              }
              padding-bottom: 10px;
            }

            ul {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              max-width: 1024px;
              padding: 10px;
              padding-top: 30px;
              margin-left: auto;

              .menu_item {
                width: 26%;
                margin-left: 30px;

                &.title {
                  position: relative;
                  transform: none;
                  width: 100%;

                  a::after {
                    display: none;
                  }
                }

              }
            }
          }

          &::after {
            position: absolute;
            left: 20px;
            bottom: 0;
            content: '';
            width: calc(100% - 40px);
            height: 1px;
            background: rgba(255, 255, 255, 0.1%);
          }

          .dropdown {
            position: absolute;
            top: -70px;
            cursor: pointer;
            padding-top: 20px;
            padding-bottom: 20px;
            border: 0;
            background: none;

            /*
            &:focus {
              outline: 0;
            } */

            &.title {
              @include icon(arrow-down, after, 'xs') {
                position: absolute;
                top: 50%;
                right: -20px;
                transform: translateY(-50%);
              }
              font-weight: var(--sans-bold);
              font-size: 14px;
              line-height: 29px;
            }
          }

          ul {
            display: none;

            li {
              a {
                color: var(--c_primary);
              }

              &.title {
                a {
                  color: var(--c_dark_grey);
                  text-transform: uppercase;
                }
              }
            }
          }
        }

        .hotels-nav-container {
          span {
            left: 20px;
          }

          .hotels-nav-content {
            &.ibercuba {
              ul{
                li {
                  &:nth-child(2) {
                    display: block !important;
                  }
                }
              }
            }

            .hotels-link {
              position: relative;
              transform: none;
              width: 20%;
              padding-bottom: 30px;
              margin-left: 10px;

              a {
                color: var(--c_dark_grey);
                text-transform: uppercase;
              }
            }

            div {
              display: flex;

              ul {
                padding-top: 0;

                &:last-child {
                  align-content: flex-start;
                  align-items: flex-start;
                }

                li {
                  width: 100%;

                  &:nth-child(2) {
                    display: none;
                  }
                }

                .title {
                  width: 100%;
                  margin-bottom: 16px;

                  a {
                    color: var(--c_primary);
                  }
                }
              }
            }

            a::after,
            a::before {
              display: none;
            }
          }



        }
      }

    }
  }

  .footer-contact {
    .wrapper {
      position: relative;
      padding-top: 25px;
      padding-bottom: 25px;

      .footer-contact-container {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin: 7px 0 0;

        .footer-contact-content {
          order: 1;
          max-width: 66%;
          padding-bottom: 5px;
        }

        .legal-information-container {
          order: 3;
          padding-top: 30px;
          border-top: 1px solid var(--c_grey_200);
        }

        .footer-contact-right {
          display: flex;
          flex-direction: column;
          order: 2;
          width: auto;
        }
      }

      &::after {
        position: absolute;
        left: 20px;
        bottom: 0;
        content: '';
        width: calc(100% - 40px);
        height: 1px;
        background: rgba(255, 255, 255, 0.1%);
      }

      .footer-contact-left,
      .footer-contact-right {
        flex-wrap: wrap;

        a {
          max-height: 48px;
        }
      }

      .footer-contact-left {
        margin-bottom: 0;

        .contact-us-btn > li {
          flex: 1 0 auto;
        }
      }

      .usa {
        .social-links {
          padding-left: 150px;
        }
      }

      .footer-ada-logo {
        position: absolute;
        left: 0;
      }
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;
    }

    .language-currency-mobile {
      display: flex;
      order: 2;
      margin: 58px 0 0;

      li {
        &:last-child::before {
          position: absolute;
          top: 50%;
          left: -10px;
          transform: translateY(-50%);
          content: '';
          width: 1px;
          height: 15px;
          background: rgba(255, 255, 255, 70%);
        }
      }
    }
  }

  .footer-bottom {
    padding: 24px 0 35px;
    overflow: hidden;

    .flex {
      > li {
        flex: 0 0 auto;
        padding: 0 15px;
        border-left: 1px solid var(--c_grey_200);
        line-height: 18px;

        a {
          @include fontSize(12);
          line-height: 18px;
        }

        &:first-child {
          padding-left: 0;
          border-left-style: none;

          &::before {
            display: none;
          }
        }

        &:last-child {
          flex: 0 1 100%;
          border-left-style: none;
          text-align: right;

          &::before {
            display: none;
          }
        }
      }
    }
  }

  #msg-currency-change {
    width: 100%;

    .currency-title {
      @include fontSize(20);

      // Se le resta el width + margin del close button
      width: calc(100% - 24px - 22px);
      padding: 22px 22px 12px;
    }

    .fancybox-close-small {
      width: 24px;
      height: 24px;
      margin: 22px 22px 0 0;
    }

    .currency-content {
      padding: 22px;
    }

    .currency-footer {
      padding: 22px;
      padding-top: 12px;

      .action-btn:first-of-type {
        margin-right: 10px;
      }
    }
  }
}

@include desktopStyles {
  .footer-my-iberostar {
    .wi-free,
    .free-wi-fi,
    .best-online-rate-guarantee,
    .my-iberostar,
    .horizons,
    .bookings-for-groups {
      padding-left: 24px;
      border-left: 1px solid var(--c_grey_200);
    }
  }

  .footer-contact {
    .wrapper {
      &::after {
        position: absolute;
        left: 20px;
        bottom: 0;
        width: calc(100% - 40px);
        height: 1px;
        background: rgba(255, 255, 255, 0.1%);
      }

      .footer-contact-right {
        justify-content: flex-end;
      }

      .footer-ada-logo {
        top: 64px;
      }
    }

    .flex {
      &.ame {
        > ul {
          flex: 0 0 auto;
          max-width: 330px;
          padding-right: 60px;
        }

        .social-links {
          flex: 0 1 100%;
        }

        > form {
          flex: 0 0 auto;
        }
      }

      &.europe {
        > ul {
          padding-right: 5%;
        }

        > ul,
        > form {
          flex: 0 1 auto;
        }

        .social-links {
          flex: 0 0 312px;

          > ul {
            flex-wrap: wrap;
          }
        }
      }
    }

    .language-selector,
    .currency-selector {
      .t-label {
        margin: 40px 0 20px;
      }
    }
  }

  .footer-nav {
    .footer-nav-container {
      .footer-nav-content {
        .wrapper {
          margin-bottom: 0;
          border-bottom: 1px solid var(--c_grey_200);

          .experiences-nav {
            .custom-css-class {
              width: 25%;

              .menu_item {
                width: 100% !important;
              }
            }

            .menu_item {
              position: relative;

              a {
                position: relative;

                &::before {
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  transform: translateY(9px);
                  content: '';
                  opacity: 0;
                  width: 100%;
                  height: 2px;
                  background: var(--c_primary);
                  transition: transform 0.3s, opacity 0.3s;
                  pointer-events: none;
                }

                &:hover {
                  &::before {
                    transform: translateY(4px);
                    opacity: 1;
                  }
                }
              }
            }
          }

          .opened-container {
            ul {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              max-width: 1024px;
              padding: 10px;
              padding-top: 30px;
              margin-left: 20%;

              &.hotels-nav-content div ul {
                margin-left: auto;
              }

              .menu_item {
                width: 26%;
                margin-left: 30px;

                &.title {
                  position: absolute;
                  transform: translateX(-90%);
                  width: 20%;
                  margin-left: 0;

                  a::after {
                    display: none;
                  }
                }
              }
            }
          }

          &::after {
            position: absolute;
            left: 20px;
            bottom: 0;
            content: '';
            width: calc(100% - 40px);
            height: 1px;
            background: rgba(255, 255, 255, 0.1%);
          }

          span {
            position: absolute;
            top: -70px;
            cursor: pointer;
            padding-top: 20px;
            padding-bottom: 20px;

            &.title {
              @include fontSize(14);

              @include icon(arrow-down, after, 'xs') {
                position: absolute;
                top: 50%;
                right: -40px;
                transform: translateY(-50%);
                margin-top: 3px;
              }
              color: var(--c_white);
              line-height: 29px;
              letter-spacing: 1.54px;
              text-transform: uppercase;

              &.opened {
                @include icon(arrow-up) {
                  margin-top: 8px;
                }
                padding-bottom: 10px;
              }
            }
          }

          ul {
            display: none;

            li {
              a {
                color: var(--c_primary);
              }

              &.title {
                a {
                  color: var(--c_dark_grey);
                  text-transform: uppercase;
                }
              }
            }
          }

          &.four_columns {
            .footer-panel-col {
              &:nth-child(1) {
                .dropdown {
                  left: 7%;
                }
              }

              &:nth-child(2) {
                .dropdown {
                  left: 27%;
                }
              }

              &:nth-child(3) {
                .dropdown {
                  left: 51%;
                }
              }

              &:last-child {
                .dropdown {
                  left: 78.5%;
                }
              }
            }
          }
        }

        .hotels-nav-container {
          .hotels-nav-content {
            .hotels-link {
              position: absolute;
              transform: translateX(-90%);
              width: 20%;

              a {
                color: var(--c_dark_grey);
                text-transform: uppercase;
              }
            }

            div {
              display: flex;

              ul {
                padding-top: 0;

                li {
                  width: 100%;

                  &:nth-child(2) {
                    display: none;
                  }
                }

                .title {
                  width: 100%;

                  a {
                    color: var(--c_primary);
                  }
                }
              }
            }

            a::after,
            a::before {
              display: none;
            }
          }
        }
      }
    }
  }

  @media only screen and (width <= 1200px) {
    .footer-contact {
      .wrapper {
        .footer-contact-container {
          flex-direction: column;
        }

        .footer-contact-right {
          align-items: baseline;
          width: 100%;
        }
      }

      .language-currency-mobile {
        margin-left: auto;
      }
    }
  }
}

@include tabletStyles {
  .footer-contact {
    .wrapper {
      padding-top: 0;

      .footer-contact-right {
        justify-content: space-between;
        width: 100%;
      }

      .footer-ada-logo {
        top: 60px;
      }
    }

    .flex {
      flex-wrap: wrap;

      &.ame {
        > ul {
          flex: 0 0 auto;
          order: 0;
          padding-right: 6%;
        }

        form {
          flex: 0 0 auto;
          order: 1;
        }

        .social-links {
          flex: 0 0 100%;
          order: 2;
        }
      }

      &.europe {
        > ul {
          flex: 0 0 33.3%;
          order: 1;
          padding-right: 6%;

          &:first-child {
            order: 0;
          }
        }

        form {
          flex: 0 0 33.3%;
          order: 2;
        }

        .social-links {
          flex: 0 0 100%;
          order: 4;
        }
      }
    }

    .contact-us-btn {
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      > li {
        margin-bottom: 12px;
      }
    }
  }

  .footer-nav {
    .wrapper {
      width: calc(100% - 40px);
      padding: 0;

      > div,
      > li {
        flex: 0 0 100%;
        padding-right: 2.5%;

        &.opened-container.hotels-nav-container {
          .hotels-nav-content {
            padding-top: 10px;

            .hotels-link {
              padding-bottom: 0;
            }
          }
        }

        &.opened-container-mobile {
          background-color: #EFEFF8;

          li {
            a {
              color: var(--c_primary);
              text-transform: uppercase;
            }

            &.title a,
            &.hotels-link a {
              padding-bottom: 0;
              color: var(--c_dark_grey);
              text-transform: uppercase;
            }
          }
        }

        > ul {
          padding: 10px 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 10%);

          &:not(.mobile-only) {
            display: block;

            > li {
              &.title,
              &.hotels-link {
                margin-top: 10px;

                &::after {
                  display: none;
                }
              }

              &:last-child {
                &::after {
                  display: none;
                }
              }
            }
          }

          > li {
            > ul {
              > li {
                &::after {
                  content: '-';
                  margin: 0 10px;
                  color: var(--c_white);
                }

                &.title {
                  &::after {
                    display: none;
                  }
                }

                &:last-child {
                  &::after {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      &.four_columns {
        .footer-panel-col {
          &:nth-child(1) {
            .dropdown {
              left: 4%;
            }
          }

          &:nth-child(2) {
            .dropdown {
              left: 22%;
            }
          }

          &:nth-child(3) {
            .dropdown {
              left: 46%;
            }
          }

          &:last-child {
            .dropdown {
              left: 75.5%;
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    .wrapper {
      display: flex;
      flex-wrap: wrap;

      .menu_item {
        padding-left: 0;
        border-left-style: none;
      }
    }
  }
}
