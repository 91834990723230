@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.internal-nav-cnt {
  background: #F6F6F6;

  .rational-page & {
    display: none;
  }
}

.internal-nav {
  position: relative;

  .menu_item {
    a {
      color: var(--interactive-text-base);
    }
  }

  &.centered,
  &.start {
    ul:not(.is-children),
    .menu {
      li:not(.drop-down-item),
      .menu_item {
        flex: 0 0 auto;
        width: auto;

        &::after {
          top: 50%;
          transform: translateY(-50%);
          height: 22px;
          margin-top: 0;
        }

        > a {
          padding: 10px 15px;
        }
      }
    }
  }

  ul:not(.is-children),
  .menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;

    &.submenu {
      position: absolute;
      top: 100%;
      left: 0;
      display: none;

      li {
        display: inline-block;
        float: none;
        border-left: 0;
      }

      a.current::before {
        display: none;
      }
    }
  }

  li:not(.drop-down-item),
  .menu_item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    &:first-child {
      padding-left: 0;
    }

    a {
      display: inline-block;
      padding: 0 24px 4px;
      font-weight: var(--sans-bold);
      transition: all 0.3s ease-in-out;

      &::before {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        content: '';
        opacity: 0;
        width: 100%;
        height: 2px;
        background: var(--c_blue_700);
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        @include interactiveElement;
      }

      &::before {
        display: none;
      }

      &.active,
      &.is-active {
        @include link-base;
      }
    }

    &:first-child {
      border-left: 0;
    }

    &:not(.multi-button) {
      &.current,
      &.active,
      &.is-active {
        > a {
          @include interactiveElement;
        }

        > .submenu {
          display: block;
        }
      }
    }
  }

  &.no-separator {
    padding: 10px 0;

    li,
    .menu_item {
      &::before {
        display: none;
      }
    }
  }

  a {
    position: relative;
    display: block;
    white-space: pre;
  }

  .more {
    position: relative;
    width: 105px;

    > a span {
      margin-left: 5px;
    }
  }

  #more-container,
  #more-container-sticky {
    position: absolute;
    top: 100%;
    right: 0;
    display: none;
    width: auto;
    padding: 10px 0;
    margin-top: 15px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(117, 124, 133, 23%);
    background: var(--c_white);

    &::before {
      position: absolute;
      left: 50%;
      bottom: 100%;
      content: '';
      width: 0;
      height: 0;
      margin-left: -10px;
      border: solid transparent;
      border-width: 10px;
      border-bottom-color: white;
      pointer-events: none;
    }

    li {
      display: block;
      padding: 3px 0;
      text-align: left;

      a {
        display: block;
        padding: 5px 20px;

        &:hover {
          text-decoration: none;
        }
      }

      &::before {
        display: none;
      }
    }
  }

  // PY04000-14
  &.with-dropdown {
    .drop-down-links {
      top: 100%;
      left: auto;
      right: 0;
      text-align: start;

      &::before {
        left: auto;
        right: 15px;
      }

      a {
        padding: 6px 30px 6px 20px;
      }
    }

    .drop-down {
      position: absolute;
    }
  }
}
