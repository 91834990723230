@use 'special_offers_banner_commons' as *;

@include desktopStyles {
  .special-offers-banner {
    .block-title {
      font-family: var(--font-serif);
    }

    .offer-banner {
      &--content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s;
      }

      &--container {
        &--image {
          height: 100%;
          overflow: hidden;
        }

        .image {
          width: 100%;
          height: 100%;

          picture,
          img {
            transform: scale(1);
            width: 100%;
            height: 100%;
            object-fit: cover;
            aspect-ratio: 2 / 3;
            transition: 0.3s ease-in-out;
          }

          picture {
            opacity: 0.9;
          }

          :hover {
            transform: scale(1.3);
          }
        }

        &:hover {
          .offer-banner--content {
            max-height: 30px;
          }

          &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 100%;
            background: transparent;
          }
        }
      }
    }
  }
}

@include tabletStyles {
  .special-offers-banner {
    .offers-container {
      &--no-swiper {
        display: flex;
        justify-content: center;
        overflow: hidden;
      }

      .container {
        position: relative;
        padding: 0;
        padding: 0 20px;
        overflow-x: auto;

        .offer-banner {
          width: 100%;

          &--container,
          &--image {
            height: 100%;
            overflow: hidden;
          }

          .image {
            width: 100%;
            height: 100%;

            picture,
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              aspect-ratio: auto;
            }
          }

        }

        .offer-card:last-child {
          margin-right: 30px;
        }

        .offer-banner--data {
          overflow: hidden;
        }

        .offer-banner--content {
          display: none;
        }
      }
    }
  }
}
