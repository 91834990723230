@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';
@use '../../shared/truncable/truncable';

.introduction-module {
  &--background {
    padding: $space4;
  }

  // image
  .image {
    &.with-play {
      position: relative;
      cursor: pointer;

      picture {
        background-color: var(--c_primary);
        transition: all 0.3s;

        img {
          display: block;
        }
      }
    }

    picture {
      position: relative;
    }

    .play-icon {
      @include fontSansBold;
      @include fontSize(14);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--c_white);

      .s {
        @include fontSize(70);
        margin-bottom: 20px;
      }
    }
  }

  .t-h3 {
    color: var(--c_blue_700);
  }

  .t-p,
  p,
  .t-label,
  li {
    color: var(--color-body-font);

    b,
    strong {
      @include fontSansBold;
    }
  }

  .textual-content {
    p + p,
    p + ul,
    ul + p {
      margin-top: 16px;
    }

    p::after {
      content: '';
    }

    &.list-with-checks ul li {
      padding-left: 30px;

      &::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-top: -3px;
        background: url('../../../images/icons/ico_check.svg') no-repeat;
        background-size: contain;
      }
    }

    .desc {
      outline: none;
    }
  }

  // gallery link
  .open-link {
    padding: 10px 16px 10px 0;
    border-radius: 25px;

    .s {
      padding-right: 2px;

      &::after {
        line-height: 20px;
      }
    }

    &::before {
      display: none;
    }

    &:not(.hidden) {
      display: inline-block;
      margin-top: 10px;
    }
  }

  // btn
  .secondary-btn {
    cursor: pointer;
    width: 100%;
  }

  // offer codes
  .offer-codes {
    .offer-code-wrapper {
      position: relative;
    }

    .offer-code {
      position: relative;
      border: 1px solid var(--c_grey_200);
      text-align: center;

      &.copied {
        border: 1px solid var(--c_green);

        .success {
          display: block;
        }
      }
    }

    .icon {
      display: block;
      color: var(--c_primary);
      font-size: 37px;
      text-align: center;
    }
  }

  // copy code interaction-related
  .code-applied {
    position: absolute;
    inset: 0;
    display: none;
    background: rgba(123, 209, 36, 95%);

    .s {
      position: relative;
      color: var(--c_white);

      // mixin?
      font-weight: 700;
      text-align: center;

      &::after {
        position: absolute;
        top: -50px;
        left: 0;
        right: 0;
        display: block;
        margin: 0 auto;
        font-size: 42px;
      }
    }

    &.visible {
      display: block;
    }
  }

  .success {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
    padding: 8px;
    margin: 0;
    border-top: 1px solid var(--c_green);
    background: rgba(123, 209, 36, 10%);
    color: var(--c_green);
    text-align: center;

    .s {
      margin-right: 5px;
      font-weight: 600;
      line-height: 16px;
    }
  }

  &.truncated {
    &.opened {
      overflow: hidden;

      .close {
        display: inline;
      }

      .more {
        display: none;
      }
    }
  }

  // when is first elememt in layout
  .default-layout:not(.session-page) {
    &:first-child {
      margin-top: 40px;
    }
  }

  .s.s-phone {
    padding-left: 34px;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      color: var(--c_primary);
      font-size: 24px;
      font-size: 1.5rem;
    }
  }

  .icons-list {
    li {
      position: relative;
      padding-left: 40px;
      margin-bottom: 20px;
    }

    .s {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      font-size: 24px;
    }
  }
}

@include commonTabletMobile {
  .introduction-module {
    .flex {
      flex-wrap: wrap;

      .offer-codes {
        width: 100%;
      }
    }
  }
}

// Rule for applying a brightness filter to an image
.ada {
  .introduction_module img {
    filter: brightness(0.7);
  }
}
