/* stylelint-disable */
.recommended-articles-insp-guide {
  position: relative;
  padding: 60px 100px;
  margin: 0 0 40px;
  z-index: 0;
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    content: '';
    width: 100%;
    height: 50%;
    background: var(--c_grey_100);
  }


  .header-image{
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translate(-50% , -50%);
    background-color: white;
    z-index: 2;
    width: 100px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;

    .title {
      position: relative;
      z-index: 1;
      display: inline-block;
      padding: 10px 40px;
      margin: 0;
      font: 700 32px/36px var(--font-serif);
      color: var(--c_blue_700);
    }
  }

  > .wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 30px;
    border: 1px solid #002855;
    background-color: var(--c_white);
    position: relative;
    overflow: visible;
  }

  .article-wrapper.swiper-wrapper {
    display: flex;
    flex-flow: row wrap;
    gap: 5px;

    .article {
      flex: 1 0 calc(33.3% - 15px);
      transition: box-shadow 300ms ease-in-out;
      display: flex;
      flex-direction: column;

      &:hover {
        box-shadow: 6px 10px 40px -7px rgb(0 0 0 / 20%);
      }

      .main-image.image-link {
        height: 300px;
        position: relative;
        overflow: hidden;
        display: block;

        picture {
          pointer-events: none;
          position: absolute;
          width: 100%;
          height: 100%;

          img{
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      .main-text {
        padding: 30px 15px;
        border: 1px solid var(--c_grey_200);
        color: var(--c_content_site_blue_800);
        flex-grow: 1;
        display: flex;
        align-items: center;

        .article-title {
          margin: 0;
          color: var(--c_blue_700);
          font: 600 18px var(--font-sans);
          pointer-events: none;
        }

      }
    }
  }

  .article-wrapper-footer {
    text-align: center;
    .s-link {
      color: var(--c_blue_700);
      text-decoration: underline;
    }
  }
}
