@use 'affiliate_tabs_commons' as *;
@use '../../shared/internal_nav/internal_nav';

@include commonTabletDesktop {
  .affiliate-tabs {
    .js-tabs-content {
      .js-tab {
        padding-top: 30px;

        > ul {
          > li {
            flex: 0 0 50%;
            width: 50%;
            max-width: 50%;
            padding: 10px 35px;
          }
        }
      }
    }
  }
}
