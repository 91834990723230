.is-truncable {
  @for $i from 1 through 12 {
    &.line-clamp-#{$i} {
      -webkit-line-clamp: $i;
    }
  }

  // For this to work is necesary the -webkit- sintax
  // https://caniuse.com/?search=line%20clamp
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  p,
  ul,
  li {
    display: inline;
  }
}
