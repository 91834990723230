@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.infographic-modules {
  position: relative;

  &.with-bg {
    @include bg-half(90px);
  }

  .pre-title {
    &.text-align-center {
      text-align: center;
    }

    &.text-align-right {
      text-align: right;
    }
  }

  .block-title {
    padding-top: 8px; // para compensar los problemas con la NoeText
    margin-bottom: 20px;

    &.text-align-center {
      text-align: center;
    }

    &.text-align-right {
      text-align: right;
    }
  }

  a {
    .s {
      color: var(--icon-color-default);
    }

    .title {
      color: var(--c_dark_grey);
    }
  }
}

.infographic-with-btn {
  text-align: center;
}

.infographic-submodules {
  > .infographic-module-item {
    .bg-image {
      display: none;
    }

    .title {
      b {
        color: var(--c_blue_700);
        font-weight: 600;
      }
    }
  }
}

.infographic-description {
  max-width: 1200px;
}

.half {
  .infographic-modules {
    .block-title {
      color: var(--c_dark_grey);
    }
  }
}
