@use 'multi_offers_slider_commons' as *;
@use '../../shared/offer_modal/offer_modal';

.multi-offer {
  &__info {
    margin-bottom: 10px;
  }
}

.multi-offer-value {
  $self: &;

  &__title,
  &__condition {
    font-size: 0.8rem;
  }
}

@include tabletStyles {
  .multi-offers {
    $self: &;

    &--one {
      #{$self}__offer {
        width: 100%;
      }
    }

    &--two,
    &--three {
      #{$self}__offer {
        width: 50%;
      }
    }

    &--three {
      #{$self}__offer {
        min-width: 400px;
      }
    }
  }
}
