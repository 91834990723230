@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';
@use '../../elements/scrollsnap_slider/scrollsnap_slider';
@use '../../shared/offer_modal/offer_modal_commons';
@use '../../shared/elements/offer_badge/offer_badge';
@use '../../shared/elements/offer_special/offer_special';

// BLOCK
.multi-offers-slider {
  &__header {
    display: block;
  }
}

// OFFERS LIST
.multi-offers {
  $self: &;

  @include swiperPaginationCustom;
  overflow: hidden;

  &__container {
    display: flex;
  }

  &--one {

    #{$self}__container {
      overflow-x: hidden;
    }
    #{$self}__offer {
      width: 100%;
      margin: 0;
    }
  }

  &--two,
  &--three {
    #{$self}__container {
      align-items: stretch;
      justify-content: space-between;
    }
  }

  &--two {
    #{$self}__offer {
      width: 50%;
    }
  }
}

// OFFER
.multi-offer {
  $self: &;
  container-type: inline-size;
  /* stylelint-disable-next-line declaration-no-important */
  height: auto !important; // !important thanks to swiper styles :)
  padding: 16px;

  &__wrapper {
    display: grid;
    grid-template:
      'picture picture card'
      'picture picture card'
      'picture picture card';
    grid-template-rows: 154px 1fr 25px;
    grid-template-columns: 1fr 140px 1fr;
    height: 250px;
    padding: 0;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 $space3 0 rgba(0, 0, 0, 10%);
  }

  &__picture {
    position: relative;
    grid-area: picture;
  }

  &__image {
    img {
      width: 100%;
      object-fit: cover;
      vertical-align: middle;
    }
  }

  &__special {
    display: inline-block;
    grid-area: special;
    margin-bottom: 20px;
  }

  &__value {
    z-index: 1;
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }

  &__title {
    @include fontSize(24);
    display: inline;
    margin-bottom: $space2;
    color: var(--c_blue_700);
    font-weight: var(--serif-bold);
    font-family: var(--font-serif);
    line-height: 1.2em;
  }

  &__badges {
    display: flex;
    flex-wrap: wrap;
    grid-area: badges;
    justify-content: end;
    margin-top: $space1;

    .offer-badge {
      padding: 0;
      background-color: var(--c_white);
      color: var(--c_dark_grey);

      &:nth-child(2) {
        margin-left: $space3;
      }
    }
  }

  &__badge {
    display: inline-block;
  }

  &__code {
    @include fontSize(12);

    .code {
      @include icon('copy', after, 's'){
        margin-left: 5px;
      }
    }
  }

  &__cta-card {
    display: flex;
    flex-direction: column;
    grid-area: card;
    justify-content: space-between;
    padding: 26px 50px;
    border-radius: 10px 0 0 10px;
    background-color: var(--c_white);
    text-align: left;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    // [PY04776-422]
    // They have requested as a solution to modify the padding of the buttons.
    // Specified in JIRA, comments
    padding:  10px 32px;
    margin-right: $space3;
    font-size: 1rem;
    white-space: nowrap;
    text-transform: uppercase;

    &:last-child {
      margin-right: 0;
    }
  }

  @container (max-width: 600px) {
    #{$self}__wrapper {
      grid-template:
        '. .... .....'
        '. .... value'
        '. .... .....'
        '. card card '
        '. .... .....';
      grid-template-rows: 10px max-content 1fr max-content 10px;
      grid-template-columns: 1fr max-content max-content;
    }

    #{$self}__picture {
      grid-area: auto;
      grid-row: 1 / 6;
      grid-column: 1 / 4;
    }

    #{$self}__image {
      img {
        aspect-ratio: 2/1;
      }
    }

    #{$self}__value {
      grid-column: 3/3;
      padding: 6px;
    }

    #{$self}__cta-card {
      position: relative;
      max-width: 400px;
      padding: 16px;
    }

    #{$self}__special {
      @include fontSize(12);
      position: absolute;
      top: -20px;
    }

    #{$self}__info {
      @include fontSize(18);
    }

    #{$self}__title {
      @include fontSize(18);
      width: 30ch;
    }

    #{$self}__button {
      @include fontSize(14);
      padding: 6px 24px;
    }

    #{$self}__code {
      margin-top: 2px;
    }
  }

  @container (max-width: 500px) {
    #{$self}__wrapper {
      grid-template-columns: 1fr max-content 26%;
    }
    #{$self}__image {
      img {
        aspect-ratio: 3/2;
      }
    }
  }

  @container (max-width: 350px) {
    margin: 0;
    #{$self}__button {
      @include fontSize(12);
      padding: $space2 $space3;
    }

    #{$self}__special {
      @include fontSize(10);
    }

    #{$self}__title {
      @include fontSize(18);
    }

    #{$self}__badge {
      @include fontSize(10);
      padding: 0 4px;
    }
  }
}

// OFFER VALUE
.multi-offer-value {
  $self: &;

  @include fontSize(14);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  border-radius: 5px 0 0 5px;
  background-color:  var(--c_white);
  color: var(--c_blue_700);
  font-family: var(--font-sans);
  line-height: 1;

  &__title,
  &__subtitle {
    margin-bottom: 4px;
    font-size: 0.9rem;
  }

  &__amount {
    margin-bottom: 4px;

    .price {
      @include fontSize(28);
      font-weight: bold;
    }

    .coin {
      font-size: 1rem;
    }

    &--discount {
      .price {
        font-weight: bold;
      }
    }

    &--currency {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;

      .price {
        margin-bottom: 0;
      }

      .coin {
        font-size: 1rem;
      }

    }
  }

  &__condition {
    text-transform: lowercase;
    word-break: break-word;
  }

  &--free {
    @include fontSize(16);
    font-weight: bold;
    text-transform: capitalize;

    #{$self}__title,
    #{$self}__subtitle {
      line-height: 1.2em;
    }
  }
}

.multi-offer-controller {
  display: flex;
  align-items: center;

  &__buttons {
    display: flex;
    margin-left: $space3;
  }

  &__button {
    width: 24px;
    height: 24px;
  }
}
