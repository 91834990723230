/* stylelint-disable scss/dollar-variable-pattern */

/*
Settings
-------------------------------------
  $VARIABLES
-------------------------------------
*/
$asset-pipeline: false;

/*
-------------------------------------
  Fonts
-------------------------------------
PLEASE keep up to date with CSS variables in global/_css_variables.scss
*/

$font-serif: 'NoeText', sans-serif !default;
$font-sans: 'Inter', sans-serif !default;
$base_lineHeight: normal !default;
$font-base: 16;
$serif-semi-bold: 600;
$serif-bold: bold;
$sans-regular: 400;
$sans-semi-bold: 600;
$sans-bold: 700;

/*
-------------------------------------
  Layout
-------------------------------------
*/

$max-width: 1240px;
$header-width: 1366px;
$base_whitespace: 1em;
$menu-width: 100%;
$menu-width-mice: 263px;
$bg-transition: transform 0.5s ease-out;

/*
  -------------------------------------
    z index
  -------------------------------------
*/

$fake_skeleton_zindex: 9;

/*
  -------------------------------------
    Breakpoints
  -------------------------------------
*/

$media-queries: mobile !default;
$media-queries-common-tablet-desktop: true !default;
$media-queries-common-tablet-mobile: false !default;

/*
  Define your breakpoints
*/

$small-desktop: 1200px;
$tablet_landscape: 1024px;
$tablet_portrait: 768px;
$tablet: 1023px;
$mobile: 767px;
$small-mobile: 450px;

/*
  -------------------------------------
    Animations
  -------------------------------------
*/

$ani-default-time: 0.25s;
$ani-default-cubic: cubic-bezier(0.4, 0, 0.2, 1);
$ani-default-esasing: ease-in-out;

/*
  -------------------------------------
    Segmentations
  -------------------------------------
*/

$segments: ocean city heritage grand;

/*
  PLEASE keep up to date with CSS variables
  --c_ocean (turqueoise)
  --c_city (cosmos)
  --c_heritage (verde salvia)
  --c_grand (red)
*/

$c_ocean: var(--c_primary);
$c_city: #002855;
$c_heritage: #A6C26E;
$c_grand: #EF5350;
$colors: (
  ocean: var(--c_ocean),
  city: var(--c_city),
  heritage: var(--c_heritage),
  grand: var(--c_grand),
);

/*
  PLEASE keep up to date with CSS variables
  --c_ocean_secondary (turqueoise secondary)
  --c_city_secondary (cloud)
  --c_heritage_secondary (verde salvia secondary)
  --c_grand_secondary (red secondary)
*/

$colors_secondary: (
  ocean: var(--c_ocean_secondary),
  city: var(--c_city_secondary),
  heritage: var(--c_heritage_secondary),
  grand: var(--c_grand_secondary),
);
$params: (
  ocean: repeat center / cover,
  city: repeat-x bottom,
  heritage: repeat center / contain,
  grand: repeat-x bottom,
);

/*
  ----------------------------------------
  datepicker vars
  ----------------------------------------
*/

$c_grey: #DBDBDB;
$c_dark_grey: #4A4A4A;

/*
  -------------------------------------
    Spacing
  -------------------------------------
  To use to iterate in mixins
*/

$sp1: 8;
$sp2: 10;
$sp3: 16;
$sp4: 24;
$sp5: 40;
$sp6: 60;
$sp7: 80;
$sp1mob: 8;
$sp2mob: 10;
$sp3mob: 16;
$sp4mob: 20;
$sp5mob: 40;
$sp6mob: 40;
$sp7mob: 60;

/*
  To use in css properties
*/

$space1: $sp1 + px;
$space2: $sp2 + px;
$space3: $sp3 + px;
$space4: $sp4 + px;
$space5: $sp5 + px;
$space6: $sp6 + px;
$space7: $sp7 + px;
$space1mob: $sp1mob + px;
$space2mob: $sp2mob + px;
$space3mob: $sp3mob + px;
$space4mob: $sp4mob + px;
$space5mob: $sp5mob + px;
$space6mob: $sp6mob + px;
$space7mob: $sp7mob + px;

/*
  z-index scale. Z-index values should always be defined
  in this vriable. This allows us to at a glance determine
  relative layers of our application and prevents bugs
  arrising from arbitrary z-index values.
*/

/*
  example of use
  .foo {
  z-index: map-get($z-index, 'header');
  }
*/

/*
  Z-Index Scale
*/

$z-index-scale: (
  'bottom-sheet': 9000,
);
