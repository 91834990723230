@use 'introduction_module_commons' as *;

@include commonTabletDesktop {
  .introduction-module {
    font-size: var(--article-font);

    .block-title {
      margin-bottom: 16px;
      color: var(--color-title-font);
      line-height: normal;

      &__content-text {
        color: var(--color-body-font);
        font-weight: var(--weight-body-font);
      }
    }

    .image {
      max-width: 600px;
      padding: 0 24px 24px;

      picture:not(.loaded) {
        display: block;

        &.big {
          min-width: 540px;
        }

        &.medium {
          min-width: 386px;
        }

        &.small {
          min-width: 220px;
        }
      }
    }
  }
}

@include desktopStyles {
  .introduction-module {
    .wrapper {
      display: flex;
      align-items: flex-start;
    }

    .images-container {
      margin-left: 30px;
    }
    .gallery-wrapper {
      .ib-swiper {
        .swiper-pagination {
          left: 50%;
        }
      }
    }

    .image {
      align-self: flex-start;
      padding: 0;

      &::after {
        bottom: 8px;
      }

      &.with-play {
        picture {
          &:hover {
            transform: scale(1.07);

            img {
              opacity: 0.15;
            }
          }
        }
      }

      .gallery-wrapper {
        height: 100%;
      }
    }

    &.w-image {
      .image {
        display: block;
      }

      .image.with-play {
        .inview:not(.loaded) {
          position: relative;
          min-height: 360px;
          overflow: hidden;
        }
      }
    }

    &.img-to-right {
      .textual-content {
        display: block;
        width: 100%;
        margin-right: 24px;
      }
    }

    .textual-content {
      flex: 1 1 48%;
      width: 48%;

      .t-label {
        display: block;
      }

      &.list-styles {
        ul li {
          padding: 0;
          margin: 0;

          &::before {
            position: static;
            display: inline-block;
            margin-right: 10px;
          }
        }

        .desc {
          outline: none;
        }
      }

      &.has-custom-bullets {
        ul,
        ol {
          li {
            margin-bottom: 16px;
            list-style: none;
          }
        }
      }
    }

    .t-p {
      margin-bottom: 16px;
    }

    p {
      &:last-of-type {
        margin-bottom: 16px;
      }
    }

    .offer-codes {
      display: flex;
      flex: 0 0 52%;
      justify-content: center;
      width: 52%;

      .offer-code {
        cursor: pointer;
        flex: 0 0 290px;
        max-width: 290px;
        padding: 24px;
        margin: 0 auto;

        &:not(.copied):hover {
          .layer.apply-code {
            display: flex;
          }
        }

        &.copied {
          padding-bottom: 60px;
        }

        &.visible {
          display: flex;
        }
      }

      .t-h3 {
        text-align: center;
      }

      .t-p {
        text-align: center;
      }

      .tag-name {
        display: flex;
        place-content: center center;
        align-items: center;
        width: 89px;
        height: 89px;
        margin: 0 auto 16px;
        border: 2px solid var(--c_blue_700);
        border-radius: 50%;

        .t-label {
          position: absolute;
          left: 0;
          right: 0;
          display: block;
          align-self: flex-start;
          margin: 0 auto;
          margin-top: 10px;
          background: var(--c_white);
          color: var(--c_primary);
          text-align: center;

          & + .icon {
            display: block;
            margin-top: 25px;
          }
        }
      }
    }

    .copy {
      @include fontSize(14);
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      color: var(--c_white);
      font-weight: 700;

      &::after {
        display: block;
        margin-bottom: 10px;
        background-repeat: no-repeat;
        color: var(--c_white);
        font-size: 42px;
      }
    }

    .layer {
      position: absolute;
      inset: 0;
      display: none;
      align-items: center;
      justify-content: center;

      &.apply-code {
        background: rgba(103, 186, 175, 95%);

        .copy::after {
          content: '';
          width: 24px;
          height: 42px;
          background-image: url('../../../images/icons/ico_timbre.svg');
        }
      }
    }

    .code-applied {
      .s {
        padding: 0 24px;
        margin-top: 45%;
      }
    }

    .secondary-btn {
      display: none;
    }

    &.text-centered {
      .textual-content {
        text-align: center;

        .t-p,
        .block-title,
        p {
          text-align: center;
        }
      }
    }

    &.text-right {
      .textual-content {
        text-align: right;

        .t-p,
        .block-title,
        p {
          text-align: right;
        }
      }
    }

    &.w-image {
      &.img-to-right {
        .wrapper {
          flex-direction: row-reverse;
        }

        .textual-content {
          margin-left: 0;
          margin-right: 30px;
        }
      }

      &.img-to-left,
      &.default-position {
        .wrapper {
          flex-direction: row-reverse;
        }

        .textual-content {
          margin-left: 30px;
          margin-right: 0;
        }
      }

    }

    &.w-100 {
      .wrapper {
        position: relative;
        display: block;
        padding-bottom: 48px;
      }

      .textual-content {
        width: 100%;

        > a {
          position: absolute;
          left: 0;
          bottom: 30px;
          right: 0;
          margin: 0 auto;
        }

        .t-p,
        p {
          text-align: left;
        }
      }

      .offer-codes {
        flex: 0 0 100%;
        flex-wrap: wrap;
        width: 100%;
        max-width: 897px;
        margin: 40px auto 0;

        .offer-code {
          margin-bottom: 20px;
        }

        .tag-name {
          width: 66px;
          height: 66px;
          margin: 0 0 16px;

          .t-label {
            left: 16px;
            margin-bottom: 0;
            text-align: left;
          }
        }

        .icon {
          margin-top: 20px;
          font-size: 24px;
        }

        .t-h3 {
          @include fontSansSemiBold;
          @include fontSize(12);
          letter-spacing: 1.54px;
          text-align: left;
          text-transform: uppercase;
        }

        .t-p {
          @include fontSize(14);
          line-height: 24px;
          text-align: left;
        }

        .code-number {
          display: flex;
          padding-left: 8px;

          > .t-fb-label {
            margin-right: 5px;

            &::after {
              content: ':';
            }

            &.code {
              &::after {
                content: '';
              }
            }
          }
        }
      }
    }

    &.w-image {
      .textual-content {
        flex: 1 1 48%;
        align-self: flex-start;
        width: 48%;
      }
    }

    &.truncated {
      &.opened {
        .wrapper {
          display: block;
        }

        .image {
          height: 100%;
        }

        .textual-content {
          width: 100%;
        }
      }

      &.img-to-right {
        &.opened {
          .wrapper {
            align-items: flex-start;
          }
        }
      }
    }
  }
}

@include tabletStyles {
  .introduction-module {
    .textual-content {
      margin-bottom: 40px;

      > .t-label {
        display: block;
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .t-p {
      margin-bottom: 16px;
    }

    p {
      &:last-of-type {
        margin-bottom: 16px;
      }

      b,
      strong {
        @include fontSansBold;
      }
    }

    .image {
      &::after {
        bottom: 16px;
      }
    }

    &.w-image {
      .image.with-play {
        width: 588px;
        height: 384px;
      }
    }

    .offer-codes {
      .offer-code {
        padding: 16px;
        margin: 0 auto 10px;

        &.copied {
          padding-bottom: 50px;

          .secondary-btn {
            display: none;
          }
        }
      }

      .t-h3 {
        @include fontSansBold;
        @include fontSize(12);
        color: var(--c_blue_700);
        line-height: 18px;
        letter-spacing: 1.54px;
        text-transform: uppercase;
      }

      .t-p {
        margin-bottom: 16px;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
      }

      .t-h3 + .t-p {
        margin-top: 8px;
      }

      .t-h3 + .code-number {
        margin-top: 16px;
      }

      .tag-name {
        display: none;
      }

      .t-h3 {
        text-align: center;
      }
    }

    .copy {
      @include fontSize(14);
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      color: var(--c_white);
      font-weight: 700;

      &::after {
        display: block;

        // remove this once we got the timbre icon
        width: 42px;
        height: 42px;
        margin-bottom: 10px;
        background-repeat: no-repeat;
        color: var(--c_white);
        font-size: 42px;
      }
    }

    .layer {
      position: absolute;
      inset: 0;
      display: none;
      align-items: center;
      justify-content: center;

      &.apply-code {
        background: rgba(103, 186, 175, 95%);

        .copy::after {
          content: '';
          width: 24px;
          height: 42px;
          background-image: url('../../../images/icons/ico_timbre.svg');
        }
      }
    }

    .code-applied {
      .s {
        margin-top: 12%;
      }
    }

    .secondary-btn {
      width: 100%;
    }

    &.img-to-right {
      .wrapper {
        display: flex;
        flex-direction: column;

        .offer-codes {
          margin-top: 0;
          margin-bottom: 40px;
        }
      }
    }

    &.w-image {
      .wrapper {
        display: flex;
        flex-direction: column;
      }

      .image {
        align-self: center;
        margin: 0 15px 25px 0;
      }

      &.img-to-right {
        .image {
          margin-left: 15px;
        }
      }
    }

    &.text-centered {
      .textual-content {
        text-align: center;

        .t-p,
        .block-title,
        p {
          text-align: center;
        }
      }
    }

    &.w-100 {
      .wrapper {
        position: relative;
        display: block;
        padding-bottom: 48px;
      }

      .textual-content {
        width: 100%;

        > a {
          position: absolute;
          left: 0;
          bottom: 30px;
          right: 0;
          margin: 0 auto;
        }

        .t-p,
        p {
          text-align: center;
        }
      }

      .offer-codes {
        flex: 0 0 100%;
        flex-wrap: wrap;
        width: 100%;
        max-width: 897px;
        margin: 40px auto 0;

        .offer-code {
          margin-bottom: 20px;
        }

        .tag-name {
          width: 66px;
          height: 66px;
          margin: 0 0 16px;

          .t-label {
            margin-bottom: 0;
          }
        }

        .icon {
          font-size: 24px;
        }

        .t-h3 {
          @include fontSansSemiBold;
          @include fontSize(12);
          letter-spacing: 1.54px;
          text-align: left;
          text-transform: uppercase;
        }

        .t-p {
          @include fontSize(14);
          line-height: 24px;
          text-align: left;
        }

        .code-number {
          display: flex;
          padding-left: 8px;

          > .t-fb-label {
            margin-right: 5px;

            &::after {
              content: ':';
            }

            &.code {
              &::after {
                content: '';
              }
            }
          }
        }
      }
    }
  }
}
