@use 'scrollsnap_slider_commons' as *;
@forward 'scrollsnap_slider_commons';

@include desktopStyles {
  .image.carousel-in-modal {
    position: relative;

    .image {
      width: 320px;
      height: 320px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .carousel-in-modal {
    .gallery-wrapper {
      width: 320px;
      height: 320px;
    }
  }
}

@include tabletStyles {
  .image.carousel-in-modal {
    position: relative;
    margin-bottom: $space3;

    .image {
      aspect-ratio: 1/1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@include media($tablet) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}
