@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

$text-color: #797979;

.slide-cards {
  @include swiperPaginationCustom;

  .heading {
    padding: 30px 0 16px;
    font-size: 2.5rem;
    text-align: center;

    .block-title {
      font-family: var(--font-serif);
    }

    p {
      color: $text-color;
      font-size: 0.875rem;
    }
  }

  & .swiper-pagination-custom::before {
    right: 120px;
  }

  .slide-cards-items-cnt {
    position: relative;
    height: auto;
    padding: 45px 0 0;
    padding-bottom: 65px;
    overflow: visible;
    background: var(--c_grey_100);

    .container {
      justify-content: center;
      max-width: 1240px;
      padding-bottom: 1.6rem;
      overflow: hidden;

      .swiper-pagination-custom {
        flex: 0 0 100%;
        padding: 10px 0 0;
        margin-top: 500px;
        margin-left: -1200px;
      }

      .ss-slider {
        box-sizing: border-box;

        .ss-slide {
          position: relative;
          cursor: pointer;
          max-width: 291px;
          margin-right: 12px;

          &:hover {
            picture {
              transform: scale(1.3) !important;
              transition: 0.3s ease-in-out !important;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          .s-arrow-right-fat {
            &::after {
              display: inherit;
              font-family: iberostar-symbols;
            }
          }
        }
      }
    }

    .navigation {
      position: absolute;
      bottom: 84px;
      right: 50px;
      display: flex;
      align-items: center;
      height: fit-content;

      & .nav-btn-prev,
      & .nav-btn-next {
        width: 30px;
        height: 30px;
      }
    }
  }

  .info-wrapper {
    position: relative;
    align-items: center;
    justify-content: flex-end;
    margin-top: -45px;

    .count-items {
      @include fontSize(14);
      font-weight: var(--sans-semi-bold);
      text-transform: uppercase;
    }
  }
}
