@use '../../../src/base_css/abstracts/abstracts' as *;
@forward '../../../src/base_css/abstracts/abstracts';

@mixin ss-slider {
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  &:not(.no-scroll) {
    scrollbar-color: var(--swiper-scrollbar-drag-bg-color) #DCEFED;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: auto;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: var(--c_primary);
    }

    &::-webkit-scrollbar-track {
      background: #DCEFED;
    }
  }

  .ss-slide {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 12px 10px 0;
    scroll-snap-align: start;
  }
}

@media (prefers-color-scheme: dark) {
  .ss-slider {
    &:not(.no-scroll) {
      scrollbar-color: var(--swiper-scrollbar-drag-bg-color) #DCEFED;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: auto;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: var(--c_primary);
      }

      &::-webkit-scrollbar-track {
        background: #DCEFED;
      }
    }
  }
}
