@use '../../../../src/base_css/abstracts/abstracts' as *;

.offer-special {
  @include fontSize(14);
  padding: $space1;
  background-color: var(--c_carrot_ada);
  color: var(--c_white);
  line-height: 1em;
  text-transform: uppercase;

  &--big {
    @include fontSize(16);
    padding: $space1 $space2;
    letter-spacing: 2.5px;
  }
}

@include mobileStyles {
  .offer-special {
    @include fontSize(12);
  }
}
