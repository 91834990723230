@use 'footer_newsletter_commons' as *;

@include commonTabletDesktop {
  $s-s: 10px;
  $s-m: 24px;
  $s-l: 40px;

  .footer-newsletter {
    padding: $s-m;

    .wrapper {
      display: flex;
    }

    .image {
      flex: 1 0 50%;
      background-position: bottom 60% left 40px;
      background-size: 80%;
    }

    .content {
      flex: 1 1 50%;
      padding: $s-l;
    }

    .t-h3 {
      margin-top: 8px;
      margin-bottom: $s-s + 2px;
    }

    .t-p {
      margin-bottom: $s-m;
    }

    .fz-col {
      margin-top: $s-m;

      &.newsletter-privacy-check {
        margin-top: $s-s + 6px;
      }
    }

    &.logged {
      .content {
        padding: $s-m;
      }

      .block-title {
        margin-top: 0;
        margin-bottom: $s-s;
      }

      .t-p {
        margin-bottom: 8px;
      }
    }
  }
}
