@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.footer-newsletter {
  background: var(--c_blue_green_100);

  .wrapper {
    padding: 0;
    background-color: var(--c_white);
  }

  .image {
    background: url('../../../images/icons/bg-footer-newsletter-envelope.svg') no-repeat bottom center;
  }

  .content {
    position: relative;
  }

  .t-h3 {
    margin: 0;
    color: var(--c_blue_700);
  }

  .fz-col {
    float: none;
  }

  .fz-field {
    display: flex;
    width: 100%;
    max-width: 400px;
    border: 0;

    .input {
      flex: 1 1 auto;
      border: 1px solid var(--c_cloud);
    }

    button {
      flex: 0 0 66px;
      min-width: 0;
    }
  }

  input {
    &::input-placeholder { /* Chrome/Opera/Safari */
      color: var(--c_grey_200);
    }

    &::placeholder { /* Firefox 19+ */
      color: var(--c_grey_200);
    }

    &:input-placeholder { /* IE 10+ */
      color: var(--c_grey_200);
    }

    &:placeholder { /* Firefox 18- */
      color: var(--c_grey_200);
    }
  }

  &.logged {
    .image {
      background: url('../../../images/icons/segmented-banner.jpg') no-repeat center /cover;
    }

    .pill {
      position: static;
      margin-bottom: 16px;
    }
  }
}

.newsletter-privacy-check {
  label {
    color: var(--c_dark_grey);
  }
}

.newsletter-suscription {
  .newsletter-privacy {
    label {
      margin-bottom: 0;
    }
  }
}
