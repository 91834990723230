@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.js-tabs {
  position: relative;
  margin-bottom: 10px;
}

.affiliate-tabs {
  .js-tabs-content {
    .js-tab {
      > ul {
        display: flex;
        flex-wrap: wrap;

        > li {
          .title {
            margin: 0 0 10px;
            color: var(--c_blue_700);
            font-size: 20px;
            font-family: var(--font-sans);
          }

          .desc {
            margin: 0;
            font-size: 14px;
            font-family: var(--font-sans);
            line-height: 18px;
          }

          ul {
            padding-left: 20px;

            li {
              position: relative;
              margin: 5px 0;

              &::before {
                position: absolute;
                top: 7px;
                left: -10px;
                content: '';
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background: var(--c_grey_400);
              }
            }
          }
        }
      }
    }
  }
}

.js-tab {
  display: none;

  &.active {
    display: flex;
  }
}
