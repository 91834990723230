@use 'infographic_modules_commons' as *;

@include desktopStyles {
  .iberostarpro-home,
  .staragent-home {
    .block-pro.visible {
      .infographic-modules {
        .block-title,
        .pre-title {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }

    .infographic-modules {
      display: flex;
      flex: 0 0 100%;
      align-items: center;
      width: 100%;
      max-width: 100%;
      height: 100vh;
      padding: 0;
      margin: 0;
      background-color: var(--c_white);

      .block-title {
        transform: translateY(200px);
        opacity: 0;
        margin-bottom: 40px;
        transition:
          transform 1s ease 0.5s,
          opacity 1s ease 0.5s;
      }

      .pre-title {
        transform: translateY(200px);
        opacity: 0;
        transition:
          transform 1s ease 0.4s,
          opacity 1s ease 0.4s;
      }

      .swiper-container {
        padding: 0 20px;
        margin-bottom: 40px;
      }

      .infographic-submodules {
        flex: 0 0 100%;
        max-width: 100%;

        > .block {
          &.with-link {
            transform: translateY(200px) scale(1);
            transition:
              transform 1s ease,
              opacity 1s ease,
              color 0.3s ease;

            &.show-item {
              transform: translateY(0) scale(1);
              opacity: 1;

              &:hover {
                z-index: 2;
                transform: translateY(0) scale(1.14);
              }
            }
          }
        }
      }

      .infographic-module-item {
        transform: translateY(200px) scale(1);
        opacity: 0;
        max-width: calc(25% - 9px);
        transition:
          transform 1s ease,
          opacity 1s ease;

        .title {
          color: var(--c_dark_grey);
        }

        &.show-item {
          transform: translateY(0) scale(1);
          opacity: 1;
        }

        .s {
          padding: 10px 0;
          color: var(--icon-color-default);
        }
      }

      &::after {
        z-index: 0;
        height: calc(50vh - 40px);
      }
    }
  }
}

@include commonTabletDesktop {
  .infographic-modules {
    padding-bottom: 14px;

    .swiper-container {
      overflow: visible;
    }

    &.one-page-hotel {
      padding-bottom: 0;
    }

    .more-info {
      display: none;
    }

    .block {
      flex: 0 1 calc(25% - 9px);
      flex-basis: calc(25% - 9px); // for ie
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: auto;
      min-height: 160px;
      padding: 16px;
      margin-left: 12px;
      border: 1px solid var(--c_grey_200);
      background: var(--c_white);
      text-align: center;

      .block .textual-content ul,
      .block .textual-content ol,
      .block .textual-content dl {
        text-align: initial;
      }

      .s {
        color: var(--icon-color-default);
      }
    }

    &.block-5 {
      .block {
        flex: 0 0 calc(20% - 8px);
        flex-basis: calc(20% - 8px); // for ie
        margin-left: 10px;
      }
    }
  }

  .infographic-submodules {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    width: 100%;

    &.submodules-align-left {
      justify-content: flex-start;
    }

    &.submodules-align-right {
      justify-content: flex-end;

    }

    .description {
      margin-top: 8px;
    }

    > .block {
      &:first-child {
        margin-left: 0;
      }

      .s {
        display: block;
        font-size: 42px;

        &.s-inspirationguide {
          &::before {
            font-size: 52px;
          }
        }
      }

      .title {
        text-align: center;
      }

      .textual-content {
        p + p,
        p + ul,
        ul + p {
          margin-top: 16px;
        }

        p::after {
          content: '';
        }

        &.list-with-checks ul li {
          padding-left: 30px;

          &::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-top: -3px;
            background: url('../../../images/icons/ico_check.svg') no-repeat;
            background-size: contain;
          }
        }

        .desc {
          outline: none;
        }
      }

      &.with-link {
        position: relative;
        z-index: 1;
        transform: scale(1);
        cursor: pointer;
        overflow: hidden;
        transition: all 0.3s;
        -webkit-font-smoothing: antialiased;

        .s,
        .title {
          transition: all 0.3s;
          -webkit-font-smoothing: antialiased;
        }

        > *:not(.bg-image) {
          position: relative;
          z-index: 1;
        }

        .bg-image {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          opacity: 0;
          width: 100%;
          height: 100%;
          transition: opacity 0.3s $ani-default-esasing;
        }

        &:hover {
          box-shadow: 6px 10px 40px -7px rgba(0, 0, 0, 20%);
        }
      }

      &.with-tooltip {
        position: relative;

        &::after {
          position: absolute;
          left: 0;
          bottom: -20px;
          content: '';
          width: 100%;
          height: 20px;
        }
      }
    }

    .image {
      img {
        width: 42px;

        &.hover {
          display: none;
        }
      }
    }
  }

  .half {
    .infographic-submodules {
      display: grid;
      grid-column-gap: 12px;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;

      .block {
        display: flex;
        flex: 0 0 calc(50% - 9px);
        flex-basis: calc(50% - 9px); // for ie
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 200px;
        margin-left: 0;
        margin-bottom: 12px;
      }
    }
  }
}
