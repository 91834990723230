@use 'news_module_item_commons' as *;

@include desktopStyles {
  .news-module {
    .card-container {
      display: flex;
      flex-direction: column;
      padding-top: 18px;

      .date {
        color: var(--c_dark_grey);
        font-size: 12px;
      }

      .block-title {
        font-size: 22px;
        line-height: 26px;
      }

      .block-link {
        margin-top: auto;
      }
    }

    .col {
      .card-item {
        margin-bottom: 25px;

        .card-container {
          position: relative;
          padding: 0 20px;

          .date {
            margin-bottom: 2px;
          }

          .block-title {
            margin-bottom: 6px;
            font-size: 20px;
          }
        }
      }
    }
  }
}

@include tabletStyles {
  .news-module {
    .info-items {
      padding: 0;
    }
  }
}
