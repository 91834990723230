@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.special-offers-banner {
  .block-title-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1240px;
    margin-bottom: 20px;

    .t-label {
      font-family: var(--font-sans);
      text-transform: none;
    }
  }

  .offers-container {
    position: relative;
    padding: 0 20px 73px;
    background-color: transparent;

    &::after {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -2;
      content: '';
      width: 100%;
      height: calc(100% - 7px - 195px);
      background: var(--c_grey_100);
    }

    .slides-centred {
      justify-content: center;
    }

    .container {
      justify-content: center;
      width: 100%;
      max-width: 1240px;
      padding-bottom: 1.6rem;
    }

    &--no-swiper {
      display: flex;
      justify-content: center;

      .offer-card {
        position: relative;
        margin-right: 12px;
      }
    }

    &--swiper {
      margin-bottom: 48px;
    }
  }

  .offer-card {
    &:hover {
      .offer-banner--image .image {
        width: 100%;
        height: 100%;

        picture,
        img {
          transform: scale(1.3);
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.3s ease-in-out;
        }

        picture {
          opacity: 0.9;
        }
      }
    }

    .offer-banner {
      width: 100%;

      &--container,
      &--image {
        height: 100%;
        overflow: hidden;
      }

      .image {
        width: 100%;
        height: 100%;

        picture,
        img {
          transform: scale(1);
          width: 100%;
          height: 100%;
          object-fit: cover;

          //aspect-ratio: 2 / 3;
          transition: 0.3s ease-in-out;
        }

        picture {
          opacity: 0.9;
        }

        :hover {
          transform: scale(1.3);
        }
      }

      &--data {
        position: absolute;
        bottom: 32px;
        z-index: 3;
        transform: translateY(60px);
        width: 90%;
        padding: 16px;
        background-color: var(--c_white);
        transition: transform 0.3s;

        .visible-text {
          word-break: break-word;

          .content {
            margin-top: 8px;
            margin-bottom: 16px;
            color: var(--c_grey_500);
            font-weight: bold;
            font-size: calc(18 / var(--font-base)* 1rem);
            line-height: 24px;
          }
        }

        .introduction {
          font-weight: 600;
          font-size: 12px;
          font-family: var(--font-sans);
          line-height: 18px;
          letter-spacing: 1.5 px;
          text-transform: uppercase;
        }

        .title {
          letter-spacing: 1.5px;
          text-transform: uppercase;
        }
      }

      &--content {
        position: relative;
        display: flex;
        justify-content: flex-start;
        width: 100%;

        .t-link {
          @include icon(arrow-right-fat, after, 'xs') {
            position: absolute;
            top: 3px;
            left: auto;
            margin-left: 8px;
            font-size: 12px;
          }
          position: relative;
          padding: 0;
          color: var(--c_primary);
          font-weight: bold;
          font-size: 14px;
          line-height: 1.2em;
        }
      }
    }
  }
}
