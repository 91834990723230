@use '../../../base_css/abstracts/abstracts' as *;
@forward '../../../base_css/abstracts/abstracts';

.destination-masonry-link {
  &__image {
    aspect-ratio: 1/1;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__info {
    text-align: center;
    color: var(--c_masterbrand_blue_700);

    &--title {
      color: var(--c_masterbrand_blue_700);
    }
  }
}
