@use '../../base_css/abstracts/abstracts' as *;
@use 'social_list_mobile';

@include commonTabletDesktop {
  .social-list-cnt {
    .block-title {
      text-align: center;
    }
  }

  .social-list {
    display: flex;
    justify-content: center;

    li {
      flex: 0 0 auto;
      margin-right: 23px;

      span {
        display: block;
        width: 93px;
        height: 93px;
        border: 3px solid var(--c_primary);
        border-radius: 50%;
        color: var(--c_primary);
        font-size: 50px;
        line-height: 77px;
        text-align: center;

        //transition: color 0.5s ease-in-out, border-color 0.5s ease-in-out;
      }
    }
  }
}
