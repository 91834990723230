@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.home-tabs {
  @include fontSize(14);
  font-weight: var(--f_font_weight_700);

  .block-tabs-name-link {
    color: var(--interactive-text-base);
    text-decoration: none;
  }

  .swiper-container {
    position: relative;
  }

  .block-desc {
    > p {
      margin: 0 0 10px;

      &:last-child {
        margin: 0;
      }
    }
  }

  &.is-lightbox {
    width: 100%;
    max-width: 982px;
    min-height: 300px;
    max-height: calc(100% - 42px);
    padding: 50px 0;
    margin: 21px 0;
    border: none;

    .wrapper {
      padding: 0;

      &-inner {
        padding: 0 40px;
      }

      .block-description {
        margin-bottom: 20px;
      }
    }

    .tabs {
      .e-filter-tabs {
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--c_grey_200);

        .content-tab {
          min-width: auto;
          padding: 0 20px;
          margin-bottom: -1px;
          white-space: nowrap;
        }
      }

      .block-wrapper {
        display: flex;

        .block-content {
          width: calc(50% + 50px);
          padding-right: 50px;
        }

        .block-image {
          width: calc(50% - 50px);

          img {
            width: 100%;
            height: 100%;
            min-height: 300px;
            object-fit: cover;
          }
        }
      }
    }
  }

  .js-tab {
    display: none;

    &.active {
      display: block;
    }
  }
}
