@use '../../../src/base_css/abstracts/abstracts' as *;
@forward '../../../src/base_css/abstracts/abstracts';

.offer-modal {
  max-width: 800px;

  &__title {
    margin-bottom: 0.5em;
    color: var(--c_blue_700);
    font-weight: normal;
    font-size: 24px;
    font-family: var(--font-serif);
    line-height: 1em;
  }

  .wrapper {
    padding: 0;
  }

  .content-wrapper {
    margin-top: 1em;
  }

  .tab-wrapper {
    display: none;

    &.active {
      display: block;
    }
  }

  .description {
    ul {
      margin-top: 10px;
      font-size: 14px;

      li {
        @include icon(check, before, 's') {
          margin-right: 6px;
          color: var(--c_green);
        }
      }
    }
  }

  .checks {
    margin-top: 10px;

    li {
      @include icon(check, before, 's') {
        margin-right: 6px;
        color: var(--c_green);
      }
      margin-bottom: 5px;
    }
  }

  .offer-dates {
    @include icon(check, before, 's') {
      margin-right: 6px;
      color: var(--c_green);
    }
    font-size: 14px;
  }

  .condition {
    ul {
      margin-left: 20px;

      li {
        list-style-type: disc;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
  }
}

.border-b {
  @include custom-border(top, 1px, $c_ocean, 250px);
}

.t-uppercase {
  text-transform: uppercase;
}
