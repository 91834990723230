@use 'slide_cards_commons' as *;
@use '../multi_offers_slider/multi_offers_slider.scss';

@include tabletStyles {
  .slide-cards {
    @include swiperPaginationCustom;

    .block-bottom {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s, margin-top 0.3s;
    }

    .dropdown {
      font-weight: bold;
      font-size: 1rem;

      .s {
        padding-left: 0.2rem;
      }

      &.less {
        display: none;
      }
    }

    .description {
      display: none;
    }

    .slide-cards-items-cnt {
      .container {
        .swiper-pagination-custom {
          margin-top: 493px;
          margin-left: -1000px;
        }

        .ss-slider {
          .ss-slide {
            place-content: flex-start flex-start;
          }

          @media screen and (orientation: portrait) {
            justify-content: flex-start;
          }
        }
      }

      .navigation {
        display: none;
      }
    }
  }
}
