@use '../../../src/base_css/abstracts/abstracts' as *;
@forward '../../../src/base_css/abstracts/abstracts';

body {
  &:not(.inspiration-guide) {
    .footer-enjoy {
      display: none !important;
    }

    .main-footer {
      margin-top: 48px;
    }

    .main-footer,
    .footer-nav,
    .footer-bottom {
      background: var(--c_white);

      a {
        @include fontSize(14);
        position: relative;
        color: var(--c_primary);
        font-weight: var(--sans-bold);
      }

      .footer-logo {
        display: none;
      }

      .t-label {
        color: var(--c_white);
      }
    }

    #msg-currency-change {
      max-width: 476px;
      padding: 0;

      .currency-title {
        @include fontSerifBold;
        display: inline-block;
        color: var(--c_black_grey_700);
      }

      .currency-content,
      .currency-footer {
        width: 100%;
      }

      .currency-content {
        border-top: 1px solid #DBDBDB;

        .subtitle,
        .desc {
          @include fontSize(14);
          @include fontSansRegular;
          margin: 5px 0;
          line-height: 22px;
        }

        .subtitle {
          @include fontSansSemiBold;
          color: var(--c_black_grey_700);
        }

        .desc + .subtitle {
          margin-top: 20px;
        }
      }

      .currency-footer {
        display: flex;

        .action-btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 50%;

          &::before {
            transform: unset;
            opacity: 0;
            height: 0;
            transition: none;
          }
        }
      }
    }

    .social-links {
      margin-top: 25px;

      > ul {
        display: flex;
        flex-wrap: wrap;

        > li {
          flex: 0 0 auto;
          margin-right: 20px;

          .s {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            background-color: var(--c_blue_green_100);
            color: var(--c_primary);
            transition:
              background-color 0.3s ease-out,
              color 0.3s ease-out;

            &::before {
              align-self: center;
              font-size: 16px;
            }

            &:hover {
              background-color: var(--c_primary);
              color: var(--c_white);
            }

            &.s-linkedin {
              &::before {
                font-size: 16px;
              }
            }

            &.s-google {
              &::before {
                font-size: 27px;
              }
            }

            &.s-youtube {
              &::before {
                font-size: 20px;
              }
            }

            &.s-inspirationguide {
              &::before {
                font-size: 26px;
              }
            }
          }
        }
      }
    }

    .footer-contact {
      .language-selector,
      .currency-selector {
        position: relative;

        .t-label {
          display: block;
        }

        select {
          cursor: pointer;
          min-width: 100%;
          min-height: 100%;
        }

        .language-selector__current {
          cursor: pointer;
        }

        .fk-select,
        .language-selector__current {
          @include fontSize(12);

          @include icon(arrow-down, after, 'xs') {
            margin: 0 0 0 8px;
            font-weight: var(--sans-bold);
          }
          font-weight: var(--sans-bold);
          line-height: 20px;
        }

        .fk-select-label::before {
          left: 0;
          margin-right: 5px;
        }
      }

      .language-selector {
        margin-right: 16px;

        .fk-select,
        .language-selector__current {
          @include icon(world, before, 's') {
            margin-right: 8px;
          }
        }

        .drop-down-links {
          box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 8%);

          .active {
            text-decoration: underline;
          }

          a {
            @include fontSize(12);
            font-weight: var(--sans-regular);
          }
        }
      }

      .currency-selector {
        .fk-select {
          @include icon(EUR, before, 's') {
            margin-right: 8px;
          }
          padding-left: 16px;
          border-left: 1px solid var(--c_grey_200);

          &.currency-EUR {
            @include icon(EUR, before);
          }

          &.currency-USD {
            @include icon(USD, before);
          }

          &.currency-GBP {
            @include icon(GBP, before);
          }

          &.currency-CHF {
            @include icon(CHF, before);
          }

          &.currency-RUB {
            @include icon(RUB, before);
          }

          &.currency-DKK {
            @include icon(DKK, before);
          }

          &.currency-SEK {
            @include icon(SEK, before);
          }

          &.currency-INR {
            @include icon(INR, before);
          }

          &.currency-JPY {
            @include icon(JPY, before);
          }

          &.currency-BRL {
            @include icon(BRL, before);
          }
        }
      }

      .app-stores {
        a {
          &::before {
            display: none;
          }
        }

        img {
          display: block;
        }
      }

      .xsmall {
        @include fontSize(14);
        line-height: 17px;

      }

      .footer-social-media {
        @include fontSize(14);

        @include icon(icon-smartphone, before, 'm') {
          margin-top: 3px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border: 0;
        border-radius: 20px;
        background: var(--c_blue_green_100);
        color: var(--c_primary);

        &::before {
          font-size: 26px;
        }
      }
    }

    .footer-my-iberostar {
      background: var(--c_grey_100);
      text-align: center;

      ul {
        display: flex;
        justify-content: center;
        width: 100%;
      }

      li {
        padding: 24px 0;
        margin-right: 24px;
        letter-spacing: 1.5px;

        &:first-child {
          border-left: 0;
        }

        a {
          @include fontSize(12);
          color: var(--c_primary);
          line-height: 16px;
          text-transform: uppercase;
        }
      }
    }

    .footer-contact {
      .contact-us-btn {
        display: flex;
        align-items: center;

        .footer-contact-btn {
          @include fontSize(16);

          @include icon(telf, before, 'm') {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            text-align: center;
          }
          display: flex;
          align-items: center;
          border: 1px solid var(--c_primary);
          color: var(--c_primary);

          &.mobile-only {
            display: none;
          }
        }

        .footer-contact-form {
          @include icon(mail, before, 'm') {
            width: 27px;
            height: 28px;
            margin-right: 10px;
            text-align: center;
          }
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .footer-need-help,
        .footer-offer-job,
        .mail-footer-contact-form,
        .cookies-config {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 24px;
        }

        .mail-footer-contact-form,
        .footer-need-help,
        .cookies-config {
          padding-left: 24px;
          border-left: 1px solid var(--c_grey_200);
        }

        .show-on-mobile {
          display: none;
        }
      }

      .footer-ada-logo {
        img {
          max-width: 123px;
        }
      }
    }

    // this code is very important for the ibercuba footer please before modified it see the webpage it's working prperly. Don't remove it
    .footer-nav {
      &.ibercuba {
        .manage-bookings-nav-container {
          button {
            position: absolute;
            top: -70px;
            left: 78%;
            cursor: pointer;
            padding-top: 20px;
            padding-bottom: 20px;
            border: 0;
            background: none;
            color: var(--c_cuba_green_700) !important;
            font-weight: var(--sans-bold);
            font-size: 14px;
            line-height: 29px;
          }
        }

        li {
          button {
            color: var(--c_cuba_green_700) !important;
          }
        }
      }

      .footer-nav-content {
        .wrapper.five_columns {
          .footer-panel-col {
            &:nth-child(2) {
              .dropdown {
                left: 16%;
              }
            }

            &:nth-child(3) {
              .dropdown {
                left: 42%;
              }
            }

            &:nth-child(4) {
              .dropdown {
                left: 65%;
              }
            }

            &:last-child {
              .dropdown {
                left: 85%;
              }
            }
          }
        }
      }
    }

  }
}

@media all and (-ms-high-contrast: none) {
  /* IE10 IE11 */
  .footer-contact {
    .contact-us-btn {
      flex-basis: 100%;
    }

    .footer-contact-right .footer-social-media.secondary-btn {
      flex-basis: 100%;
      max-width: 290px;
      height: 40px;
    }
  }
}

.ada {
  .phone-list {
    text-align: center;
  }
}
