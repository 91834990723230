@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.block-call-center {
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color-default);
  }

  .call-center{
    $self: &;
    display: flex;

    &__chat{
      display: flex;

      &-image{
        &-circle{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-bubbles{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-bubble{
          @include fontSize(16);
          @include fontSansRegular;
          width: fit-content;
          border-radius: 20px 20px 20px 0;
          background-color: var(--c_blue_700);
          color: var(--c_white);
          line-height: 16px;
        }
      }
    }

    &__title{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      &-text{
        color: var(--c_grey_600);
        letter-spacing: 1px;
      }

      &-button{
        background-color: var(--c_white);
      }
    }

    &__subtitle{
      color: var(--c_grey_600);

    }
  }
}
