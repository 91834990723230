@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.e-filter-tabs {
  display: flex;
  flex: 0 0 auto;
  %tab-text {
    font: var(--sans-semi-bold) 12px/18px var(--font-sans);
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  .content-tab {
    @extend %tab-text;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 190px;
    height: 50px;
    border: 1px solid var(--c_grey_200);
    border-top-color: var(--c_grey_100);
    border-left: 0;
    background-color: var(--c_grey_100);

    &:last-child {
      border-right: 0;
    }

    a {
      @extend %tab-text;
      display: flex;
      flex: 0 0 100%;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0;

      &:hover,
      &:focus {
        &::before {
          display: none;
        }
      }
    }

    .s {
      margin-right: 4px;
      font-size: 20px;

      &.extra-bottom {
        margin-bottom: 3px;
      }

      &.extra-top {
        margin-top: 6px;
      }
    }

    &.active {
      padding-top: 0;
      border-top: 3px solid var(--c_dark_grey);
      border-bottom: 2px solid var(--c_white);
      background-color: var(--c_white);
      color: var(--c_dark_grey);
    }
  }
}
