@use 'news_module_commons' as *;

@include desktopStyles {
  .news-module {
    .block {
      margin-bottom: 24px;
    }

    .block-title {
      @include fontSize(32);
    }

    img {
      width: 100%;
      max-height: 263px;
      object-fit: cover;
    }

    .wrapper {
      &.col {
        padding: 0;

        .block {
          margin-bottom: 5px;
        }

        .card-container {
          display: flex;
          flex-direction: column;
          margin-left: 20px;

          .block-title {
            @include fontSize(20);
            line-height: 24px;
          }
        }

        .block-link {
          margin-top: auto;
        }

        .flex {
          flex-flow: column wrap;
          justify-content: flex-start;

          .card-item {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 158px 1fr;
          }
        }
      }

      &.row {
        &::before {
          position: absolute;
          left: 0;
          z-index: -2;
          content: '';
          width: 100%;
          height: 334px;
          background: var(--c_grey_100);
        }

        .block-title-header {
          margin-top: 50px;
        }

        .flex {
          flex-flow: row wrap;
          justify-content: flex-start;

          .card-item {
            flex-basis: calc(33.33% - 10px);
            margin: 0 15px 12px 0;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

@include tabletStyles {
  .news-module {
    .wrapper > .flex {
      flex-direction: column;
    }

    .block {
      display: flex;
      margin-bottom: 15px;

      .card-image img {
        max-width: 200px;
        margin-right: 15px;
      }

      .card-container {
        display: flex;
        flex-direction: column;

        .block-link {
          margin-top: auto;
        }
      }
    }
  }
}

.half {
  .news-module {
    .t-h3 {
      &.mb-4 {
        margin: 0;
      }
    }
  }
}
