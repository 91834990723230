@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.block-content {
  .block-desc {
    ul {
      margin-left: 20px;
      list-style-type: disc;
    }

    li {
      list-style-type: inherit;
    }
  }
}

.b-home-tabs-container-item {
  &.active {
    will-change: transform, opacity;

    .block-content-bg {
      animation: fade-in-out 0.6s;
    }

    img {
      animation: show-block-info 0.9s;
    }
  }
}
