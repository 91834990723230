@use 'home_tabs_item_commons' as *;

@include commonTabletDesktop {
  .grand-collection {
    .b-home-tabs-container-item {
      .b-home-tabs-item {
        .block-content {
          .block-content-bg {
            .block-subtitle {
              position: relative;
              display: inline-block;

              &::before {
                position: absolute;
                top: 50%;
                right: -84px;
                transform: translateY(-50%);
                content: '';
                width: 74px;
                height: 1px;
                background-color: var(--c_grand);
              }
            }
          }
        }
      }
    }
  }

  .b-home-tabs-container-item {
    display: none;
    width: 100%;
    height: auto;

    &:nth-last-of-type(2n) {
      .b-home-tabs-item {
        flex-direction: row-reverse;

        .block-content {
          margin: 40px 0 0 -70px;
        }
      }
    }

    .b-home-tabs-item {
      display: flex;
      flex: 0 0 100%;
      align-items: flex-start;
      justify-content: center;

      .block-content {
        z-index: 1;
        padding: 40px;
        margin: 40px -70px 0 0;
        box-shadow: 6px 34px 61px -28px rgba(0, 0, 0, 20%);
        background-color: var(--c_white);
        background-size: 200% 200%;

        .block-content-bg {
          height: auto;

          .block-subtitle {
            margin: 0 0 8px;
            font-size: 12px;
          }

          .block-title {
            margin-bottom: 10px;
            font-weight: 600;
          }

          .block-desc {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

      .block-image {
        position: relative;
        width: 100%;
        max-width: 549px;
        height: 100%;
        max-height: 331px;
        aspect-ratio: 594/331;

        .image:not(.loaded) {
          padding-top: 63.5%;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

@include desktopStyles {
  .b-home-tabs-container-item {
    .b-home-tabs-item {
      .block-content {
        width: 100%;
        max-width: 546px;
      }
    }
  }
}

@include tabletStyles {
  .b-home-tabs-container-item {
    .b-home-tabs-item {
      .block-content {
        flex: 0 0 60%;
        padding: 24px;

        .block-content-bg {
          .block-desc {
            font-size: 12px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
